// Here you can add other styles
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

body {
  font-family: "Nunito", sans-serif;
}

.c-sidebar-logo {
  height: 60px;
  margin: 10px 0;
}

.font-weight-medium {
  font-weight: 600;
}

.c-sidebar-logo-minimized {
  height: 20px;
  margin: 10px 0;
}

.c-header-logo {
  height: 60px;
  margin: 10px 0;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background: transparent;
}

.display-block {
  display: block;
}

.profile-container {
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  padding: 16px 32px;
}

.fake-image-profile-container {
  background: #768192;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  .image-profile {
    color: #fff;
  }
}

.c-datatable-filter {
  width: 285px;

  input.form-control {
    width: 285px;
  }
}

.user-informations {
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  margin-top: 24px;

  .username {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;
  }
}

.button-toggle-collapse {
  width: 100%;

  display: flex;
  align-items: flex-start;
  outline: none;
  border: none;
  padding: 0.5rem 1.25rem;
}

.container-massives {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
}

.container-massives {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
}

td {
  font-weight: bold;

  &.ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.c-datatable-items-per-page {
  margin-right: 4rem;
}

.font-family-secondary {
  font-family: "Poppins", sans-serif;
}

.avatar-img {
  height: 48px;
  width: 48px;
}

.bg-gray-2 {
  background-color: #dedede;
}

.font-size-18 {
  font-size: 18px;
}

.column-success {
  background-color: #13910a;
  padding-top: 40px;
  padding-bottom: 40px;
  border: none;
  border-radius: 0;
  flex: 1;
  height: 100%;

  /*   bg-success pt-5 pb-2 w-100 ${styles} border-0 rounded-0*/
}

.column-confirmed {
  background-color: #1a284a;
  padding-top: 40px;
  padding-bottom: 40px;
  border: none;
  border-radius: 0;
  flex: 1;
  height: 100%;

  /* bg-primary mb-0 h-100 w-100 border-0 rounded-0 */
}

.column-detected {
  background-color: #ee9032;
  padding-top: 40px;
  padding-bottom: 40px;
  border: none;
  border-radius: 0;
  flex: 1;
  height: 100%;

  /* bg-warning mb-0 h-100 w-100 border-0 rounded-0 */
}

.column-manual {
  background-color: #00adb8;
  padding-top: 40px;
  padding-bottom: 40px;
  border: none;
  border-radius: 0;
  flex: 1;
  height: 100%;

  /* bg-info mb-0 h-100 w-100 border-0 rounded-0 */
}

.subtitle {
  font-size: 1rem;
}

.card-mount {
  font-size: 1.5rem;
}

.massives-container-width {
  max-width: 1332px;
}

.header-tools {
  max-width: 1294.5px;
}

small {
  font-size: 0.875rem;
}

.react-daterange-picker__wrapper {
  border: none !important;
}

.react-daterange-picker__inputGroup__input {
  color: $primary;
}

.form-control,
.input-group-text {
  border-color: $primary;
}

.form-control {
  height: calc(1.5em + 0.9rem + 2px);
}

.form-control[readonly],
.form-control[disabled] {
  border: none;
}

.prevent-full {
  max-width: 1294.5px;
}

.search-wrapper {
  position: relative;
  min-height: 38.91px;

  .searchBox {
    position: absolute;
  }
}

td {
  vertical-align: middle !important;
}

textarea[disabled],
textarea[readonly] {
  background-color: #d8dbe0;
  border: none !important;
  border-radius: 3px;
}

.d-none {
  display: none;
}

th .d-inline {
  margin-left: 0;
}

.table thead th {
  padding: 14px 0;
}

th svg.position-absolute {
  right: 0;
  right: inherit;
}

th.position-relative.pr-4 {
  padding: 1rem 1rem 1rem 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 1024px) {
  .column-success {
    width: 100% !important;
    height: 25% !important;
  }
  .column-confirmed {
    width: 100% !important;
    height: 25% !important;
  }
  .column-detected {
    width: 100% !important;
    height: 25% !important;
  }

  .column-manual {
    width: 100% !important;
    height: 25% !important;
  }

  .container-massives {
    padding: 10px;
    margin-left: 15px;
  }
}

.group-inputs {
  margin: -45px auto 20px 5px;
  border: none;
}

.group-inputs input {
  width: 285px;
  border: none;
}

.group-prepend {
  display: flex;
  flex-direction: row;
  align-items: center;

  border: 1px solid #1a284a;
  border-radius: 3px;
  padding-right: 10px;
  outline: none;
}

div.position-relative.table-responsive {
  min-height: 500px;
}

.group-prepend input:focus {
  box-shadow: none;
}

#table-renegotiation tr {
  height: 60px !important;
  z-index: 999 !important;
}

.modal-content {
  width: 80%;
  margin: auto 10%;
}

.c-button-card {
  width: 200px;
  border-radius: 5px;
  
  display: flex;
  align-items: center;
}

.c-card-title{
  font-size: 16px;
  font-weight: bold;
}

.c-card-subtitle {
  font-size: 24px;
  font-weight: bold;
  color:#ee9032;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 656px;
    margin: 1.75rem auto;
  }

  .modal-content {
    padding: 1rem 2.7rem;
  }
}

@media (max-width: 768px) {
  .group-inputs input {
    width: 200px;
  }
}

@media (max-width: 500px) {
  .group-inputs input {
    width: 100px;
  }
}

/**
* SELECT DE VIABILIDADE
*/

.select-search {
  width: 300px;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 20px 40px 20px 16px;
  background: #fff;
  border: 1px solid $primary;
  // box-shadow: 0 0 .125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
  padding: 0;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background-color: $primary;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba($primary, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background-color: $primary;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  box-shadow: 0 0 .125rem rgba(0, 0, 0, 0.9);
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}